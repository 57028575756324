<template>
  <div class="container">
    <header-back name='兑换成功'></header-back>
    <section class="top44 suc-cen">
      <van-image
        round
        :src="require('../../assets/image/success.png')"
      />
      <h5>{{ title }}</h5>
      <p>
        {{ title }}，可持续关注物流信息，如遇问题可联系客服15812340000
      </p>

      <van-button
        block
        color="#FF26A8"
        plain
        round
        size="small"
        @click="routerJump"
      >查看订单</van-button>
      <br />
      <van-button
        block
        size="small"
        round
        color="linear-gradient(to right, #FF6AC1, #FF26A8)"
        @click="routerJump2"
      >
        分享商品
      </van-button>
    </section>
  </div>
</template>

<script>
import HeaderBack from "../../components/header-back";

export default {
  name: "Sexch",
  components: { HeaderBack },
  data() {
    return {
      title: "兑换成功",
    };
  },
  created() {
    this.type = this.$route.query.type;
    this.title = "兑换成功";
    if (this.type) {
      this.title = "购买成功";
    }
  },
  methods: {
    routerJump() {
      this.$router.replace(`/order`);
    },
    routerJump2() {
      this.$router.replace(`/commodity?id=${this.$route.query.pid}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  padding: 16px 15px;
  box-sizing: border-box;
  text-align: center;
  height: 100%;
  background: #fff;
}
.suc-cen {
  padding: 15%;
  h5 {
    font-weight: 400;
    color: #292929;
    padding-top: 30px;
    padding-bottom: 16px;
  }
  P {
    color: #979797;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 30px;
  }
}
</style>
